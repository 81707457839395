import React, { Component } from 'react';
import { makeCall } from '../utils';

class DikiHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      words: [],
    };
  }

  async componentDidMount() {
    const words = await makeCall('GET', null, '/services/diki-history');
    this.setState({ words });
  }

  render() {
    return (
      <div className='diki-history'>
        {this.state.words.map((word) => {
          return <div className='diki-history-item' onClick={() => this.props.onAddThing(word)}>{word}</div>;
        })}
      </div>
    );
  }
}

export default DikiHistory;
