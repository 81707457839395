import React, { Component } from 'react';
import {
  ArrowDownOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

import { If } from './If';

const tabComponent = (n) =>
  <span dangerouslySetInnerHTML={{ __html: '&nbsp;'.repeat(4).repeat(n) }}></span>;

class SimpleForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      previewActive: false,
    };
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  onClick() {
    if (!this.state.value) {
      return;
    }
    this.props.onClick(this.state.value);
    this.setState({ value: '' });
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.onClick();
    }
  }

  changeDikiContentVisibility() {
    this.setState({ previewActive: !this.state.previewActive });
  }

  getActionButton() {
    const content = this.props.children || this.props.content;
    if (typeof content === "string") {
      return <>
        <div className={`action ${this.props.className}`} onClick={() => this.onClick()}>
          {content}
        </div>
        <If cond={this.props.withDikiContent}>
          <Button type="link" icon={<ArrowDownOutlined />} size={'large'} onClick={() => this.changeDikiContentVisibility()} />
        </If>
      </>;
    }
    return <>
      <div onClick={() => this.onClick()}>
        {content}
      </div>
    </>;

  }

  setNewValue(value) {
    this.setState({
      value,
      previewActive: false,
    }, () => this.onClick());
  }

  getDikiPreview() {
    const words = this.props.payload || [];
    let result = []
    for (const word of words) {
      result.push(<div><b>{word.name}</b>{word.otherNames ? ", " + word.otherNames.join(',') : ''}</div>)
      result.push(word.addition ? <div>{word.addition}</div> : '')
      for (const tran of word.translations || []) {
        result.push(<div style={{ display: 'flex', flexDirection: 'row' }}>
          ⚫ {tran.translation.map(t => <div className='diki-translation' onClick={() => this.setNewValue(t)}>{t + ', '}</div>)}
        </div>)
        for (const sentence of tran.sentences || []) {
          result.push(<div className='diki-sentence' onClick={() => this.setNewValue(sentence.value)}>{tabComponent(2)}{' ' + sentence.value}</div>)
        }
      }
      for (const speech of word.speeches || []) {
        result.push(<div>{speech.speechName}</div>)
        for (const tran of speech.translations) {
          result.push(<div style={{ display: 'flex', flexDirection: 'row' }}>
            ⚫ {tran.translation.map(t => <div className='diki-translation' onClick={() => this.setNewValue(t)}>{t + ', '}</div>)}
          </div>)
          for (const sentence of tran.sentences || []) {
            result.push(<div className='diki-sentence' onClick={() => this.setNewValue(sentence.value)}>{tabComponent(2)}{' ' + sentence.value}</div>)
          }
          for (const t of tran.trans || []) {
            result.push(<div onClick={() => this.setNewValue(t)}>{tabComponent(1)}{'⚪ ' + t}</div>)
          }
        }
      }
    }
    return <div className="diki-preview">{result}</div>;
  }

  render() {
    const { disabled = false } = this.props;
    return (
      <div className="simple-form">
        <div className="simple-form-row">
          <input
            type="text"
            className="input-text"
            value={this.state.value}
            onChange={(e) => this.onChange(e)}
            onKeyDown={(e) => this.handleKeyDown(e)}
            disabled={disabled}
          />
          {this.getActionButton()}

        </div>
        <If cond={this.state.previewActive}>
          {this.getDikiPreview()}
        </If>
      </div>
    );
  }

}

export default SimpleForm;