import React, { Component } from 'react';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom'
import {
  Link
} from "react-router-dom";

import SimpleForm from './SimpleForm';

import {
  PlayCircleOutlined,
  ImportOutlined,
  SettingFilled,
  MenuOutlined,
} from '@ant-design/icons';

import { makeCall, XYZA_HEADER } from '../utils';


class Cards extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      mode: 'default',
      loading: true,
      notAuthenticated: false,
      xyzaValue: '',
    };
    this.payload = [];
  }

  async loadCards() {
    const cards = await makeCall('GET', null, ``);
    if (cards.notAuthenticated) {
      return this.setState({ notAuthenticated: true, loading: false });
    }
    this.setState({ cards, loading: false });
  }

  async addCard(name) {
    this.setState({ loading: true });
    const body = JSON.stringify({ type: 'card', name });
    await makeCall('PUT', body);
    await this.loadCards();
  }

  async deleteCard(cardId) {
    this.setState({ loading: true });
    const body = JSON.stringify({ type: 'card', cardId });
    await makeCall('DELETE', body);
    await this.loadCards();
  }

  toggleMode() {
    this.setState({ mode: 'edit' === this.state.mode ? 'default' : 'edit' });
  }

  async addThing(cardId, word) {
    this.setState({ loading: true });
    const body = JSON.stringify({ type: 'thing', cardId, word });
    await makeCall('PUT', body);
    await this.loadCards();
  }

  async componentDidMount() {
    await this.loadCards();
  }

  async getDikiHistory() {
    return makeCall('GET', null, '/services/diki-history');
  }

  async updateCardName(cardId, name) {
    this.setState({ loading: true });
    const body = JSON.stringify({ type: 'card', cardId, name });
    await makeCall('POST', body);
    await this.loadCards();
    this.toggleMode();
  }

  onXyzaChange(e) {
    this.setState({ xyzaValue: e.target.value });
  }

  approveXyza() {
    localStorage.setItem(XYZA_HEADER, this.state.xyzaValue);
    this.loadCards();
  }

  render() {
    const { loading, notAuthenticated, xyzaValue } = this.state;
    return (
      <div className="container">
        <div className="top-panel">
          <Link to={`/import`}>
            <Button type="link" icon={<ImportOutlined />} size={'large'} />
          </Link>
          <SimpleForm
            className="add-action"
            onClick={(v) => this.addCard(v)}
            content='+'
            disabled={loading}
          />
          <Button type="link" icon={<SettingFilled />} size={'large'} onClick={() => this.toggleMode()} />
          <Link to={`/settings`}>
            <Button type="link" icon={<MenuOutlined />} size={'large'} />
          </Link>
        </div>
        <div className={`cards-container${loading ? ` container--loading` : ``}`}>
          {notAuthenticated ? <div>
            <input onChange={(v) => this.onXyzaChange(v)} value={xyzaValue} />
            <button onClick={() => this.approveXyza()}>Approve</button>
          </div> : null}
          {
            this.state.cards.map(card =>
              <div className={"card card-compact"} key={card.id}>
                <div className="card-body">
                  <div className="card-header">
                    <div className="card-title">
                      {this.state.mode === 'edit'
                        ? <SimpleForm
                          className="add-action"
                          onClick={(v) => this.updateCardName(card.id, v)}
                          content='+'
                          value={card.name}
                          disabled={loading}
                        />
                        : card.name}
                    </div>
                  </div>
                  <SimpleForm
                    className="add-action"
                    onClick={(v) => this.addThing(card.id, v)}
                    content='+'
                    disabled={loading}
                  />
                </div>
                <div className="card-footer">
                  <Link to={`/${card.id}`}>
                    <Button type="link" icon={<PlayCircleOutlined />} size={'large'} />
                  </Link>
                  {this.state.mode === 'edit' ? <div className="action delete-action" onClick={() => this.deleteCard(card.id)}>x</div> : null}
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Cards);
