import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Input } from 'antd';
import {
  ForwardOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { makeCall } from '../utils';

const { TextArea } = Input;

class ImportView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };
    this.payload = [];
  }

  async createCard(things) {
    const body = JSON.stringify({ type: 'card', name: 'english', things });
    await makeCall('PUT', body);
    this.setState({ value: '' });
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  async onImport() {
    const things = this.state.value
      .split('\n')
      .map(line => line.trim())
      .filter(line => line)
      .map(line => line.replace(/\s+/g, ' '))
      .map(line => line.split(' - '))
      .filter(line => line.length < 3)
      .map(line => ({ word: line[0], translations: line[1] ? [line[1]] : [] }));
    await this.createCard(things);
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          type="primary"
          icon={<RollbackOutlined />}
          size={'large'}
          onClick={() => this.props.history.goBack()}
          style={{ height: 'auto' }} />
        <div style={{ display: 'flex', flex: 1 }}>
          <TextArea rows={4} onChange={(e) => this.onChange(e)} value={this.state.value} style={{ backgroundColor: '#272626', color: 'white' }} />
          <Button
            type="primary"
            icon={<ForwardOutlined />}
            size={'large'}
            onClick={() => this.onImport()}
            style={{ height: 'auto' }} />
        </div>
      </div>
    );
  }
}

export default withRouter(ImportView);
