import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { If } from './If';
import SimpleForm from './SimpleForm';
import DikiHistory from './DikiHistory';
import { Button } from 'antd';
import {
  SettingFilled,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  ForwardOutlined,
  CheckCircleFilled,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { makeCall } from '../utils';
import config from '../config';

const tabComponent = (n) =>
  <span dangerouslySetInnerHTML={{ __html: '&nbsp;'.repeat(4).repeat(n) }}></span>;

class Card extends Component {

  constructor(props) {
    super(props);
    this.state = {
      thing: null,
      cardId: null,
      processed: 0,
      active: 0,
      mode: 'default',
      loading: false,
      audioUrl: null,
      payload: [],
      isDikiPreview: false,
      dikiPreviewData: {},
    };
    this.payload = [];
  }

  async loadNextThing() {
    const cardId = this.props.match.params.id;
    if (cardId) {
      const response = await makeCall('GET', null, `/next/${cardId}`);
      const { thing, processed, active, audioUrl, payload } = response;
      const mode = thing.translations.length === 0 ? 'edit' : 'default';
      const _payload = payload ? payload.words : [];
      this.setState({ thing, processed, mode, active, audioUrl, payload: _payload });
    }
  }

  async acceptThing(cardId, thingId, isProcessed = false) {
    this.setState({ loading: true, mode: 'default' });
    const body = JSON.stringify({ cardId, thingId, isProcessed });
    const resp = await makeCall('POST', body, `/next`);
    const { thing, processed, active, audioUrl, payload } = resp;
    const _payload = payload ? payload.words : [];
    const mode = thing.translations.length === 0 ? 'edit' : 'default';
    this.setState({ thing, processed, active, mode, loading: false, audioUrl, payload: _payload });
    await this.playAudio(thing.word);
  }

  componentDidMount() {
    this.loadNextThing();
  }

  updateThing() {
    this.setState({ mode: this.state.mode === 'edit' ? 'default' : 'edit' });
  }

  async deleteThing(cardId, thingId) {
    const body = JSON.stringify({ type: 'thing', cardId, thingId });
    await makeCall('DELETE', body);
    await this.loadNextThing();
  }

  async updateThingName(cardId, thingId, word) {
    this.setState({ mode: 'default' });
    const body = JSON.stringify({ type: 'thing', cardId, thingId, word });
    await makeCall('GET', body);
    await this.loadNextThing();
  }

  async addTranslation(cardId, thingId, name) {
    const body = JSON.stringify({ type: 'translation', cardId, thingId, name });
    await makeCall('PUT', body);
    await this.loadNextThing();
  }

  async addSentence(cardId, thingId, translationId, sentence) {
    const body = JSON.stringify({ type: 'sentence', cardId, thingId, translationId, sentence });
    await makeCall('PUT', body);
    await this.loadNextThing();
  }

  async deleteTranslation(cardId, thingId, translationId) {
    const body = JSON.stringify({ type: 'translation', cardId, thingId, translationId });
    await makeCall('DELETE', body);
    await this.loadNextThing();
  }

  async deleteSentence(cardId, thingId, translationId, sentenceId) {
    const body = JSON.stringify({ type: 'sentence', cardId, thingId, translationId, sentenceId });
    await makeCall('DELETE', body);
    await this.loadNextThing();
  }

  async getDikiHistory() {
    return makeCall('GET', null, '/services/diki-history');
  }

  async playAudio(word) {
    if (word) {
      const audio = new Audio(`${config.baseUrl}/audio/ang/${word}.mp3`);
      await audio.play();
    }
  }

  showDikiHistory() {
    this.setState({
      mode: this.state.mode === 'diki-history' ? 'default' : 'diki-history'
    });
  }

  async onAddThing(word) {
    const cardId = this.props.match.params.id;
    const body = JSON.stringify({ type: 'thing', cardId, word });
    await makeCall('PUT', body);
    await this.loadNextThing();
  }

  openInDiki(word) {
    window.open(`https://www.diki.pl/slownik-angielskiego?q=${word}`, '_blank');
  }

  getDikiPreview() {
    const words = this.state.payload || [];
    const { cardId, thingId, translationId, translationFor } = this.state.dikiPreviewData;
    const handleClick = async (sentene) => {
      await this.addSentence(cardId, thingId, translationId, sentene);
    };
    let result = []
    for (const word of words) {
      result.push(<div><b>{word.name}</b>{word.otherNames ? ", " + word.otherNames.join(',') : ''}</div>)
      result.push(word.addition ? <div>{word.addition}</div> : '')
      for (const tran of word.translations || []) {
        result.push(<div style={{ display: 'flex', flexDirection: 'row' }}>
          ⚫ {tran.translation.map(t => <div className='diki-translation' onClick={() => handleClick(t)}>{t + ', '}</div>)}
        </div>)
        for (const sentence of tran.sentences || []) {
          result.push(<div className='diki-sentence' onClick={() => handleClick(sentence.value)}>{tabComponent(2)}{' ' + sentence.value}</div>)
        }
      }
      for (const speech of word.speeches || []) {
        result.push(<div>{speech.speechName}</div>)
        for (const tran of speech.translations) {
          result.push(<div style={{ display: 'flex', flexDirection: 'row' }}>
            ⚫ {tran.translation.map(t => <div className='diki-translation' onClick={() => handleClick(t)}>{t + ', '}</div>)}
          </div>)
          for (const sentence of tran.sentences || []) {
            result.push(<div className='diki-sentence' onClick={() => handleClick(sentence.value)}>{tabComponent(2)}{' ' + sentence.value}</div>)
          }
          for (const t of tran.trans || []) {
            result.push(<div onClick={() => handleClick(t)}>{tabComponent(1)}{'⚪ ' + t}</div>)
          }
        }
      }
    }
    return <div>
      <div className='translation-for'>{translationFor}</div>
      {result}
    </div>;
  }

  showDikiView(cardId, thingId, translationId, translationFor) {
    this.setState({
      isDikiPreview: true,
      dikiPreviewData: { cardId, thingId, translationId, translationFor },
    });
  }

  closeDikiPreview() {
    this.setState({ dikiPreviewData: {}, isDikiPreview: false });
  }

  render() {
    const isEditMode = this.state.mode === 'edit';
    const { processed, active, loading, thing } = this.state;
    const cardId = this.props.match.params.id;
    return (
      <div className="container">
        <div className="card-container">
          <If cond={thing !== null}>
            <If cond={!this.state.isDikiPreview}>
              <div className="card card-more">
                {thing ? <>
                  <div className="thing-view-header">
                    <If cond={!isEditMode}>
                      <div onClick={() => this.playAudio(thing.word)} className="thing-title"> {thing.word} </div>
                    </If>
                    <If cond={isEditMode}>
                      <SimpleForm
                        onClick={(v) => this.updateThingName(cardId, thing.id, v)}
                        value={thing.word}
                        withDikiContent={true}
                        content={<div></div>}
                      />{/* <Button type="link" icon={<EditOutlined />} size={'large'} /> */}
                    </If>
                    <div className="card-actions">
                      <If cond={isEditMode}><div className="action delete-action" onClick={() => this.deleteThing(cardId, thing.id)}>x</div></If>
                      <Button type="link" icon={<SettingFilled />} size={'large'} onClick={() => this.updateThing()} />
                      <Button type="link" icon={<img src="https://www.diki.pl/images/diki/diki_logo.svg" />} size={'large'} onClick={() => this.openInDiki(thing.word)} />
                      <Button type="link" icon={<ArrowRightOutlined />} size={'large'} onClick={() => this.showDikiHistory()} />
                    </div>
                  </div>

                  <div className="thing-translations">
                    <If cond={isEditMode}>
                      <SimpleForm
                        className="add-action"
                        payload={this.state.payload}
                        withDikiContent={true}
                        onClick={(v) => this.addTranslation(cardId, thing.id, v)}
                        content='+'
                      />
                    </If>
                    {thing.translations.map(translation => <div key={translation.id} className="translation">
                      <div className="translations-header">
                        <If cond={isEditMode}><div className="action delete-action" onClick={() => this.deleteTranslation(cardId, thing.id, translation.id)}>x</div></If>
                        <div onClick={() => this.showDikiView(cardId, thing.id, translation.id, translation.name)}>{translation.name}</div>
                      </div>
                      <If cond={isEditMode}>
                        <SimpleForm
                          className="add-action"
                          onClick={(v) => this.addSentence(cardId, thing.id, translation.id, v)}
                          payload={this.state.payload}
                          withDikiContent={true}
                          content='+'
                        />
                      </If>
                      {translation.sentences.map(sentence => <div key={sentence.id} className="sentence">
                        <div className="sentences-header">
                          <If cond={isEditMode}><div className="action delete-action" onClick={() => this.deleteSentence(cardId, thing.id, translation.id, sentence.id)}>x</div></If>
                          {sentence.sentence}
                        </div>
                      </div>)}
                    </div>)}
                  </div>
                </> : <div className="empty">Empty</div>}
                <div className="thing-footer">
                  <Button
                    type="primary"
                    icon={<RollbackOutlined />}
                    size={'large'}
                    onClick={() => this.props.history.goBack()}
                    style={{ height: 'auto' }} />
                  <div className="indicator">{processed}</div>
                  <Button
                    type="primary"
                    icon={<CheckCircleFilled />}
                    size={'large'}
                    onClick={() => this.acceptThing(cardId, thing.id, true)}
                    style={{ marginRight: '3px', width: '60px' }}
                    disabled={active === 0 || loading}
                    loading={loading}
                  />
                  <Button
                    type="primary"
                    icon={<ForwardOutlined />}
                    size={'large'}
                    onClick={() => this.acceptThing(cardId, thing.id)}
                    style={{ flex: 1 }}
                    disabled={active === 0 || active === 1 || loading}
                    loading={loading}
                  />
                  <div className="indicator"><span>{active}</span></div>
                </div>
              </div>
            </If>
            <If cond={this.state.isDikiPreview}>
              <div class="card">
                <div className="thing-translations">
                  {this.getDikiPreview()}
                </div>
                <div className="thing-footer">
                  <Button
                    type="primary"
                    icon={<RollbackOutlined />}
                    size={'large'}
                    onClick={() => this.closeDikiPreview()}
                    style={{ height: 'auto' }} />
                </div>
              </div>
            </If>
          </If>
          <If cond={this.state.mode === 'diki-history'}>
            <DikiHistory onAddThing={(v) => this.onAddThing(v)} />
          </If>
        </div>
      </div>
    );
  }
}

export default withRouter(Card);
