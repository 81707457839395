import config from './config';
export const XYZA_HEADER = 'xyza-header';

export async function makeCall(method = 'GET', body, path = '') {
  const resp = await fetch(`${config.baseUrl}/cards${path}`, {
    method,
    ...(method === 'GET' ? {} : { body }),
    // mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
      [XYZA_HEADER]: localStorage.getItem('xyza-header'),
    },
  });
  return resp.json();
}
