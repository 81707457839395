import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Cards from './components/Cards';
import Card from './components/Card';
import ImportView from './components/ImportView';
import Settings from './components/Settings';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/import" exact={true} children={<ImportView />} />
        <Route path="/settings" exact={true} children={<Settings />} />
        <Route path="/" exact={true} children={<Cards />} />
        <Route path="/:id" exact={true} children={<Card />} />
        <Route children={<div>NOT FOUND</div>} />
      </Switch>
    </Router>
  );
}

export default App;
