import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  ForwardOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: ''
    };
  }

  componentDidMount() {
    const settings = localStorage.getItem('settings') || '';
    this.setState({ settings });
  }

  async onSaveSettings() {
    this.props.history.push('/');
  }

  render() {
    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          type="primary"
          icon={<RollbackOutlined />}
          size={'large'}
          onClick={() => this.props.history.goBack()}
          style={{ height: 'auto' }} />
        <div style={{ display: 'flex', flex: 1 }}>
          <input value={''} style={{ backgroundColor: '#272626', color: 'white' }} />
          <Button
            type="primary"
            icon={<ForwardOutlined />}
            size={'large'}
            onClick={() => this.onSaveSettings()}
            style={{ height: 'auto' }} />
        </div>
      </div>
    );
  }
}

export default withRouter(Settings);
